export const state = () => ({
  tokenInfo: {
    accessToken: '',
    refreshToken: ''
  },
  loginInfo: {},
  signUpInfo: {}
})

export const mutations = {
  SET_TOKEN_INFO: (state, tokenInfo) => {
    state.tokenInfo = tokenInfo
  },
  SET_LOGIN_INFO: (state, loginInfo) => {
    state.loginInfo = loginInfo
  },
  SET_SIGN_UP_INFO: (state, signUpInfo) => {
    state.signUpInfo = signUpInfo
  }
}

export const actions = {
  nuxtServerInit({ commit }, { app }) {
    const tokenInfo = app.$cookies.get('tokenInfo') || { accessToken: '', refreshToken: '' }
    commit('SET_TOKEN_INFO', tokenInfo)
  },
  setTokenInfo({ commit }, tokenInfo) {
    this.$cookies.set('tokenInfo', tokenInfo, {
      path: '/',
      // httpOnly: true,
      secure: true,
      sameSite: 'strict',
      maxAge: 60 * 60 * 24 * 7
    })
    commit('SET_TOKEN_INFO', tokenInfo)
  },
  setLoginInfo({ commit }, loginInfo) {
    commit('SET_LOGIN_INFO', loginInfo)
  },
  setSignUpInfo({ commit }, signUpInfo) {
    commit('SET_SIGN_UP_INFO', signUpInfo)
  }
}

export const getters = {
  getTokenInfo: (state) => {
    return state.tokenInfo
  },
  getLoginInfo: (state) => {
    return state.loginInfo
  },
  getSignUpInfo: (state) => {
    return state.signUpInfo
  }
}
